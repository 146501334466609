import React from 'react';
import './CWX-IconsMainPage.css';
import LaunchButton from '../LaunchButton';
import { useNavigate } from 'react-router-dom';

const CWXIconsMainPage = () => {
  document.body.style.overflow = 'auto';
  const Navigate = useNavigate();

  return (
    <div className="CWXIconsMainPageMainDiv">
      <p
        onClick={() => {
          Navigate('/');
        }}
        id="closeButton"
      >
        X
      </p>
      <div className="horizontalFlexDivCWXIconsMain">
        <div className="groupFlexDivCWXIconsMain">
          <h1 style={{ color: 'white', margin: '0', marginTop: '2rem' }}>
            CWX-Icons
          </h1>
          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            by Colin
          </h2>
          <h3 style={{ color: 'gray', margin: '0', marginTop: '2rem' }}>
            Last updated: 30.05.2024
          </h3>
        </div>
        <div className="groupFlexDivCWXIconsMain">
          <LaunchButton componentName={'cwx-icons'} />

          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            ReactTS
          </h2>
        </div>
      </div>
      <div className="imageDivCWXIconsMain">
        <img alt="cwx-icons_home" src="cwx-icons_home_show.png"></img>
        <img
          alt="cwx-icons_profile_icon"
          src="cwx-icons_profile_icon_show.png"
        ></img>
      </div>
    </div>
  );
};

export default CWXIconsMainPage;
