import React from 'react';
import './NotFoundPage.css';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="mainDivNotFoundPage">
      <h1>Page not found!</h1>
      <h2
        className="goBackHomeBTNNotFoundPage"
        onClick={() => {
          navigate('/');
        }}
      >
        Home
      </h2>
    </div>
  );
};

export default NotFoundPage;
