import React from 'react';
import './CWX-IconsMainPage.css';
import LaunchButton from '../LaunchButton';
import { useNavigate } from 'react-router-dom';

const KMQGithubViewer = () => {
  document.body.style.overflow = 'auto';
  const Navigate = useNavigate();

  return (
    <div className="CWXIconsMainPageMainDiv">
      <p
        onClick={() => {
          Navigate('/');
        }}
        id="closeButton"
      >
        X
      </p>
      <div className="horizontalFlexDivCWXIconsMain">
        <div className="groupFlexDivCWXIconsMain">
          <h1 style={{ color: 'white', margin: '0', marginTop: '2rem' }}>
            KMQ-Github-Viewer
          </h1>
          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            by TheKamek
          </h2>
          <h3 style={{ color: 'gray', margin: '0', marginTop: '2rem' }}>
            Last updated: 03.06.2024
          </h3>
        </div>
        <div className="groupFlexDivCWXIconsMain">
          <LaunchButton componentName={'kmq-github-viewer'} />

          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            ReactJS
          </h2>
        </div>
      </div>
      <div className="imageDivCWXIconsMain">
        <img
          alt="kmq-github-viewer-TheKamek"
          src="kmq-github-viewer-TheKamek.png"
        ></img>
        <img
          alt="kmq-github-viewer-Thuner2007"
          src="kmq-github-viewer-Thuner2007.png"
        ></img>
      </div>
    </div>
  );
};

export default KMQGithubViewer;
