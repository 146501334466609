import React, { useState } from 'react';
import './ProjectOverlay.css';
import { useNavigate } from 'react-router-dom';

const ProjectOverlay = ({
  height,
  width,
  title,
  creator,
  fontSize,
  shortDescription,
  landingPage,
}) => {
  const navigate = useNavigate();

  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });

  const handleMouseDown = (event) => {
    setInitialMousePos({ x: event.clientX, y: event.clientY });
    console.log('Mouse down');
  };

  const handleMouseDownRun = (event) => {
    setInitialMousePos({ x: event.clientX, y: event.clientY });
    console.log('Mouse down');
    event.stopPropagation();
  };

  const handleMouseUp = (event) => {
    if (
      initialMousePos.x === event.clientX &&
      initialMousePos.y === event.clientY
    ) {
      navigate(`/${landingPage}`);
    }
  };

  const handleMouseUpRun = (event) => {
    if (
      initialMousePos.x === event.clientX &&
      initialMousePos.y === event.clientY
    ) {
      navigate(`/${landingPage}/run`);
    }
    event.stopPropagation();
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="mainDivProjectOverlay"
      style={{ height, width }}
    >
      <p
        style={{
          fontSize: fontSize / 1.6,
        }}
      >
        {shortDescription}
      </p>
      <h1 style={{ fontSize: fontSize * 1.6 }}>{title}</h1>
      <h2
        style={{
          fontSize: fontSize / 1.75,
        }}
      >
        Created by: {creator}
      </h2>
      <button
        className="startButtonProjectOverlay"
        style={{
          height: height / 5,
          width: height / 5,
          fontSize: fontSize / 2,
        }}
        onMouseDown={handleMouseDownRun}
        onMouseUp={handleMouseUpRun}
      >
        &#x23FB;
      </button>
    </div>
  );
};

export default ProjectOverlay;
