import React from 'react';
import './NavBar.css';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <div className="mainDivNavBar">
      <h1
        onClick={() => {
          navigate('/');
        }}
        className="textHomeNavBar"
      >
        Projects
      </h1>
    </div>
  );
};

export default NavBar;
