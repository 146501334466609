import React from 'react';
import './LaunchButton.css';
import { useNavigate } from 'react-router-dom';

const LaunchButton = ({ componentName }) => {
  const Navigate = useNavigate();
  return (
    <>
      <button
        onClick={() => {
          Navigate(`/${componentName}/run`);
        }}
        id="launchButton"
      >
        Launch
      </button>
    </>
  );
};

export default LaunchButton;
