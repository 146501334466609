import React from 'react';
import StopAppBar from '../StopAppBar/StopAppBar';

const CWXCDXRunner = () => {
  return (
    <>
      <StopAppBar redirectName="cwx-cdx" />

      <iframe
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 'calc(100%)',
          height: 'calc(100%)',
          zIndex: 10000,
        }}
        title="kmq-github-viewer"
        src="
        https://github.com/thuner2007/cdx"
      ></iframe>
    </>
  );
};

export default CWXCDXRunner;
