import React, { useEffect, useState } from 'react';
import './Map.css';
import ProjectOverlay from './MapComponents/ProjectOverlay';

const Map = () => {
  const [project, setProject] = useState({
    size: window.innerHeight / 1.3,
    top: window.innerHeight / 10,
    left: window.innerWidth / 2.5,
  });
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [hideHint, setHideHint] = useState(false);

  document.body.style.overflow = 'hidden';

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      const offsetX = event.clientX - mousePosition.x;
      const offsetY = event.clientY - mousePosition.y;
      const newLeft = project.left + offsetX;
      const newTop = project.top + offsetY;

      setProject((prevProject) => ({
        ...prevProject,
        left: newLeft,
        top: newTop,
      }));
      setMousePosition({ x: event.clientX, y: event.clientY });
    }
  };

  useEffect(() => {
    const handleScroll = (event) => {
      setHideHint(true);
      setProject((prevProject) => {
        const newSize = prevProject.size - event.deltaY / 1.5;

        if (newSize > 50 && newSize < window.innerWidth) {
          return {
            ...prevProject,
            size: newSize,
          };
        } else {
          return prevProject;
        }
      });
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  useEffect(() => {
    if (isMouseDown) {
      setHideHint(true);

      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isMouseDown]);

  return (
    <div id="MainDivMap" onMouseDown={handleMouseDown}>
      <h2 style={{ opacity: hideHint ? '0' : '1' }} id="hintMap">
        You can explore the map! Click, drag, and scroll to discover more.
      </h2>
      <button
        onClick={() => {
          setProject({
            size: window.innerWidth / 3,
            top: window.innerHeight / 5,
            left: window.innerWidth / 5,
          });
        }}
        className="reloadButtonMap"
      >
        &#10227;
      </button>
      <div
        className="projectMover"
        style={{
          top: `${project.top}px`,
          left: `${project.left}px`,
        }}
      >
        <div
          className="projectGroupDiv"
          style={{
            top: `${project.size / 3}px`,
          }}
        >
          <ProjectOverlay
            title={'CWX-Wordlet'}
            creator={'Colin'}
            shortDescription={'Learn your words with Wordlet!'}
            landingPage={'wordlet'}
            height={project.size / 4}
            width={project.size / 2}
            fontSize={project.size / 25}
          />

          <ProjectOverlay
            title={'CWX-Icons'}
            creator={'Colin'}
            shortDescription={'A lot of icons for your projects!'}
            landingPage={'cwx-icons'}
            height={project.size / 4}
            width={project.size / 2}
            fontSize={project.size / 25}
          />

          <ProjectOverlay
            title={'CWX-CDX'}
            creator={'Colin'}
            shortDescription={'Colins Design Experience!'}
            landingPage={'cwx-cdx'}
            height={project.size / 4}
            width={project.size / 2}
            fontSize={project.size / 25}
          />
        </div>
        <div
          className="projectGroupDiv"
          style={{
            top: `${project.size}px`,
          }}
        >
          <ProjectOverlay
            title={'KMQ-Github-Viewer'}
            creator={'TheKamek'}
            shortDescription={'View someones Github profile!'}
            landingPage={'kmq-github-viewer'}
            height={project.size / 4}
            width={project.size / 1.5}
            fontSize={project.size / 25}
          />
        </div>
      </div>
    </div>
  );
};

export default Map;
