import React from 'react';
import './StopAppBar.css';
import { useNavigate } from 'react-router-dom';

const StopAppBar = (redirectName) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/${redirectName.redirectName}`);
      }}
      className="mainDivStopAppBar"
    >
      &#x23FB;
    </div>
  );
};

export default StopAppBar;
