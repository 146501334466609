import React from 'react';
import StopAppBar from '../StopAppBar/StopAppBar';

const WordletRunner = () => {
  return (
    <>
      <StopAppBar redirectName="wordlet" />

      <iframe
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 'calc(100%)',
          height: 'calc(100%)',
          zIndex: 10000,
        }}
        title="Wordlet"
        src="
        https://cwx-wordlet-nu.vercel.app"
      ></iframe>
    </>
  );
};

export default WordletRunner;
