import React from 'react';
import StopAppBar from '../StopAppBar/StopAppBar';

const CWXIconsRun = () => {
  document.body.style.overflow = 'hidden';

  return (
    <>
      <StopAppBar redirectName="cwx-icons" />

      <iframe
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 'calc(100%)',
          height: 'calc(100%)',
          zIndex: 10000,
        }}
        title="CWX-IconsRun"
        src="
  https://cwx-icons-web-git-main-thuner2007s-projects.vercel.app"
      ></iframe>
    </>
  );
};

export default CWXIconsRun;
