import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './CWX-IconsMainPage.css';
import LaunchButton from '../LaunchButton';
import { useNavigate } from 'react-router-dom';
import CWXCDX from './CWXCDX';

const CWXCDXMainPage = () => {
  document.body.style.overflow = 'auto';
  const Navigate = useNavigate();

  return (
    <div className="CWXIconsMainPageMainDiv">
      <p
        onClick={() => {
          Navigate('/');
        }}
        id="closeButton"
      >
        X
      </p>
      <div className="horizontalFlexDivCWXIconsMain">
        <div className="groupFlexDivCWXIconsMain">
          <h1 style={{ color: 'white', margin: '0', marginTop: '2rem' }}>
            CWX-CDX
          </h1>
          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            by Colin
          </h2>
          <h3 style={{ color: 'gray', margin: '0', marginTop: '2rem' }}>
            Last updated: 07.06.2024
          </h3>
        </div>
        <div className="groupFlexDivCWXIconsMain">
          <LaunchButton componentName={'cwx-cdx'} />

          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            (ReactTS)
          </h2>
        </div>
      </div>
      <div className="imageDivCWXIconsMain">
        {/* <img alt="cwx-icons_home" src="cwx-icons_home_show.png"></img>
        <img
          alt="cwx-icons_profile_icon"
          src="cwx-icons_profile_icon_show.png"
        ></img> */}
      </div>
      <div className="DocumentationDivCDX">
        <h1>Documentation</h1>
        {Object.entries(CWXCDX).map(([componentName, componentData]) => (
          <div key={componentName} style={{ width: '100%' }}>
            <h2>{componentName}</h2>
            <p>
              <strong>Description:</strong> {componentData.description}
            </p>
            <p>
              <strong>Info:</strong> {componentData.info}
            </p>
            <h3>Props:</h3>
            <ul>
              {Object.entries(componentData)
                .filter(
                  ([key]) => !['description', 'info', 'example'].includes(key)
                )
                .map(([propName, propDetails]) => (
                  <li key={propName}>
                    <strong>{propName}</strong>: {propDetails}
                  </li>
                ))}
            </ul>
            <h3>Example:</h3>
            <SyntaxHighlighter language="tsx" style={docco}>
              {componentData.example}
            </SyntaxHighlighter>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CWXCDXMainPage;
