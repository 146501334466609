import React from 'react';
import StopAppBar from '../StopAppBar/StopAppBar';

const KMQGithubViewerRunner = () => {
  return (
    <>
      <StopAppBar redirectName="kmq-github-viewer" />

      <iframe
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 'calc(100%)',
          height: 'calc(100%)',
          zIndex: 10000,
        }}
        title="kmq-github-viewer"
        src="
        https://kmq-github-viewer.vercel.app"
      ></iframe>
    </>
  );
};

export default KMQGithubViewerRunner;
