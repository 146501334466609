import React from 'react';
import './WordletMainPage.css';
import LaunchButton from '../LaunchButton';
import { useNavigate } from 'react-router-dom';

const WordletMainPager = () => {
  const Navigate = useNavigate();
  return (
    <div className="wordletMainPageMainDiv">
      <p
        onClick={() => {
          Navigate('/');
        }}
        id="closeButton"
      >
        X
      </p>
      <div className="horizontalFlexDivWordletMain">
        <div className="groupFlexDivWordletMain">
          <h1 style={{ color: 'white', margin: '0', marginTop: '2rem' }}>
            Wordlet
          </h1>
          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            by Colin
          </h2>
          <h3 style={{ color: 'gray', margin: '0', marginTop: '2rem' }}>
            Last updated: 17.05.2024
          </h3>
        </div>
        <div className="groupFlexDivWordletMain">
          <LaunchButton componentName={'wordlet'} />
          <h2 style={{ color: '#5C8CBD', margin: '0', marginTop: '2rem' }}>
            ReactJS
          </h2>
        </div>
      </div>
      <div className="imageDivWordletMain">
        <img alt="wordlet_loginscreen" src="wordlet_loginscreen_show.png"></img>
        <img alt="wordlet_cardmode" src="wordlet_cardmode_show.png"></img>
      </div>
    </div>
  );
};

export default WordletMainPager;
