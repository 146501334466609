import './App.css';
import Map from './components/Map/Map';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import CWXIconsRun from './components/Projects/CWX-IconsRun';
import CWXIconsMainPage from './components/Projects/CWX-IconsMainPage';
import WordletRunner from './components/Projects/WordletRunner';
import WordletMainPager from './components/Projects/WordletMainPager';
import KMQGithubViewer from './components/Projects/KMQ-Github-ViewerMainPage';
import KMQGithubViewerRunner from './components/Projects/KMQGithubViewerRunner';
import CWXCDXRunner from './components/Projects/CWX-CDXRunner';
import CWXCDXMainPage from './components/Projects/CWX-CDXMainPage';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route
          index
          element={
            <div>
              <Map />
            </div>
          }
        />
        <Route path={'wordlet'} element={<WordletMainPager />} />
        <Route path={'cwx-icons'} element={<CWXIconsMainPage />} />
        <Route path={'kmq-github-viewer'} element={<KMQGithubViewer />} />
        <Route path={'cwx-cdx'} element={<CWXCDXMainPage />} />
        <Route
          path={'kmq-github-viewer/run'}
          element={<KMQGithubViewerRunner />}
        />
        <Route path={'wordlet/run'} element={<WordletRunner />} />
        <Route path={'cwx-cdx/run'} element={<CWXCDXRunner />} />
        <Route path={'cwx-icons/run'} element={<CWXIconsRun />} />
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
